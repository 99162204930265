import { Dispatch, SetStateAction } from 'react'
export type ID = undefined | null | number

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100
  total?: number
  total_records?: number
  totalPage?:number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Response<T> = {
  data?: any
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  postData: Object
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, postData: {} }

export type ListViewContextProps = {
  selected: Array<ID>
  setSelected?: Dispatch<SetStateAction<any>> | any
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  allData?: any
  setAllData?: Dispatch<SetStateAction<any>> | any
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  setListData?: Dispatch<SetStateAction<any>> | any
  listData?: any
  setListOptions?: Dispatch<SetStateAction<any>> | any
  listOptions?: any
  createResponseContext?: any
  isAllSelected: boolean
  disabled: boolean
  countData?: any
  setCountData?: Dispatch<SetStateAction<any>> | any
}

export const initialListView: ListViewContextProps = {
  selected: [],
  listData: [],
  listOptions: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setListData: () => { },
  setListOptions: () => { },
  setSelected: () => { },
  setCountData: () => { },
  isAllSelected: false,
  disabled: false,
}
