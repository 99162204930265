import { Dispatch, SetStateAction } from 'react'
// import { getUserData } from '../../../../components'

// const user = getUserData()

// const eRoleType = user?.eRoleType

// const adminAndHR = eRoleType === 1 || eRoleType === 2 || eRoleType === 3

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100
  // total?: number
  totalRecords: number
  totalPage: any
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Response<T> = {
  data?: any
  payload?: {
    page?: number
    limit?: number
    totalRecords?: number
    totalPage?: number
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & any

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  postData: Object
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, postData: {} }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
}
