import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'custom-toast'
  },
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true
})

export default Toast;