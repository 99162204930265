/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { KTSVG } from '../../../helpers/components/KTSVG';

type Props = {
  callback: any,
  copy: boolean
}

const HeaderUserMenu: FC<Props> = (props) => {
  // console.log(props)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
    document.location.reload()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user.vProfileImg ? user.vProfileImg : ''} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.vFirstName ? user.vFirstName : ""} {user.vLastName ? user.vLastName : ""}
            </div>
            <div><small>{user.vDesignation}</small></div>
            <div className='d-flex align-items-center'>
              <CopyToClipboard text={user.email} onCopy={() => props.callback(true)}>
                <span className='cursor-pointer'>
                  <KTSVG path="/media/icons/duotone/General/Envelope.svg" className="svg-icon-gray-400 svg-icon-2" />
                </span>
              </CopyToClipboard>
              {props.copy ? <span style={{ color: 'green' }}> &nbsp; Email Copied.</span> : null}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        {/* <Link to={'/profile'} className='menu-link px-5'> */}
        <a href="https://overview.zealousweb.com/profile" target='_blank' rel="noreferrer" className='menu-link px-5' >
          My Profile
        </a>
        {/* </Link> */}
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
