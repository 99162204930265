import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { PortFolios } from '../core/_models'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { usersColumns } from './columns/_column'
import { PortFolioPagination } from '../component/pagination/PortFolioPagination'
import { Loader } from '../../../components'

const DataTable = () => {

  const PortFolioData = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => PortFolioData, [PortFolioData])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  }
  )

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-table-color fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<PortFolios>) => {
                return <CustomHeaderColumn key={column.id} column={column} />
              }

              )}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<PortFolios>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <>
                <tr>
                  <td colSpan={10}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <Loader />}
      {Array.isArray(rows) && rows.length > 0 && <PortFolioPagination />}
    </>
  );
};

export { DataTable }
