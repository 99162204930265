import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const PASSWORD_API = process.env.REACT_APP_API_URL_PASSWORD
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`
export const LOGIN_URL = `${API_URL}/login`
export const PASSWORD_CHANGE_URL = `${PASSWORD_API}/reset-password`
export const REQUEST_PASSWORD_URL = `${PASSWORD_API}/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function changePassword(password: string, password_confirmation: string, token: any, email: any) {
  return axios.post(PASSWORD_CHANGE_URL, {
    password,
    password_confirmation,
    token,
    email
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email
  })
}

export async function getUserByToken(token: string) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bareer ${token}`
  };
  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL, { 'headers': headers }).then((response) => response.data)
}
