/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from '../core/crud-helper/index'
import { getDashboardData } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)

const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [postData, setPostData] = useState<any>(state)
  const updatedPostData = useMemo(() => (state), [state]);
  useEffect(() => {
    let query = stringifyRequestQuery(postData);
    var updatedQuery = stringifyRequestQuery(updatedPostData);
    if (query !== updatedQuery) {
      setPostData(updatedPostData)
    } else {
      refetch();
    }

  }, [updatedPostData])

  var query1 = stringifyRequestQuery(postData);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query1}`,
    () => {
      const list = getDashboardData(postData)
      return list
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, postData }}>
      {children}
    </QueryResponseContext.Provider>
  )
}
const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    totalPage: 0,
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response || !response.payload) {
    return defaultPaginationState
  }
  return response.payload
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
