// import React from 'react'
import { useRef } from "react"
import { PortFolios } from '../../core/_models'
import { Column } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { onlyDateFormat } from '../../../../components'
// import CopyToClipboard from "react-copy-to-clipboard"
// import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG';
// import Overlay from 'react-bootstrap/Overlay';
import ReactTooltip from 'react-tooltip'

// import Popover from 'react-bootstrap/Popover'

const usersColumns: Array<Column<PortFolios>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Project' className='min-w-125px' />,
    accessor: 'projectName',
    Cell: ({ row }) => {

      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-12'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {row?.original?.projectName}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Client' className='min-w-200px' />,
    accessor: 'client',
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {row?.original?.client}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Industry' className='min-w-200px' />,
    id: 'industry',
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {!Array.isArray(row?.original?.industry) ?
                <span className={`badge badge-secondary me-2 mb-1`} >
                  {row?.original?.industry}
                </span>
                : ''}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    accessor: 'startDate',
    Cell: ({ row }) => {
      return <span className='text-dark fw-bolder text-hover-primary  mb-1 fs-6'>{onlyDateFormat(row?.original?.startDate, 'YYYY-MM-DD')}</span>
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Service' className='min-w-200px' isSortable={false} />,
    id: 'service',
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {row?.original?.service?.length !== 0
                ? row?.original?.service &&
                row?.original?.service?.map((item: any, index: any) => (
                  <span className={`badge badge-secondary me-2 mb-1`} key={`tag-${index}`}>
                    {item?.vName}
                  </span>
                ))
                : ''}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Technology' className='min-w-200px' isSortable={false} />
    ),
    id: 'technology',
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {row?.original?.technology?.length !== 0
                ? row?.original?.technology &&
                row?.original?.technology?.map((item: any, index: any) => (
                  <span className={`badge badge-secondary me-2 mb-1`} key={`tag-${index}`}>
                    {item?.vName}
                  </span>
                ))
                : ''}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Framework' className='min-w-200px' isSortable={false} />
    ),
    id: 'framework',
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
              {row?.original?.framework?.length !== 0
                ? row?.original?.framework &&
                row?.original?.framework?.map((item: any, index: any) => (
                  <span className={`badge badge-secondary me-2 mb-1`} key={`tag-${index}`}>
                    {item?.vName}
                  </span>
                ))
                : ''}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='NDA Protected' className='min-w-150px' isSortable={false} />
    ),
    id: 'eNDAprotected',
    Cell: ({ row }) => {
      const getClass: string = (() => {
        switch (row.original.eNDAprotected) {
          case '0':
            return 'light-danger' 
          case '1':
            return 'light-success'
          default:
            return 'light-danger'
        }
      })()
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-10'>
            <div className={`badge badge-${getClass}`}>
              {row.original.eNDAprotected === "1" ? "Yes" : "No"}
            </div>
          </div>
        </div>
      )
    },
  },
  {
    Header: (props) => (< CustomHeader tableProps={props} title='URL' isSortable={false} className='min-w-125px' />)
    ,
    accessor: 'url',
    Cell: ({ row }) => {
      const toolRef = useRef<any>(null);
      // @ts-ignore: Unreachable code error
      return (
        <div className='row'>
          <div className='col-md-16 d-flex justify-content-between'>
            <span className='text-dark fw-bolder text-hover-primary  mb-1 fs-6'>{(row.original.url !== "" && row.original.url !== null) && row.original.url}</span>
            {
              (row.original.url !== "" && row.original.url !== null) &&
              // <CopyToClipboard text={row.original.url} onCopy={copyUrl}>
              <>
                {/* <span className="cursor-pointer px-1" ref={target} onClick={copyUrl}> */}
                <span className="px-2">
                  {/* <KTSVG path="/media/logos/icons8-link-96.svg" className="svg-icon-gray-400 svg-icon-2" /> */}
                  {/* <Overlay target={target.current} show={copied} placement="top">
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: 'absolute',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          padding: '2px 10px',
                          color: 'white',
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        Copied
                      </div>
                    )}
                  </Overlay> */}
                  <i
                    className='fas fa-copy cursor-pointer'
                    data-tip='Copied!'
                    title='Copy url'
                    data-event='tempclick'
                    data-text={`${row.original.url}`}
                    onClick={(e: any) => {
                      navigator.clipboard.writeText(e.target.getAttribute('data-text'))
                      ReactTooltip.show(toolRef.current)
                    }}
                    ref={(ref) => (toolRef.current = ref)}
                  ></i>
                  {/* {index === 0 && ( */}
                  <ReactTooltip
                    afterShow={() => {
                      setTimeout(() => {
                        ReactTooltip.hide(toolRef.current)
                      }, 3000)
                    }}
                  ></ReactTooltip>
                </span>
              </>
              //  </CopyToClipboard
            }
          </div>
        </div >
      )
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Url Status' className='min-w-125px' />
    ),
    accessor: 'urlStatus',
    Cell: ({ row }) => {
      return (
        <div className='row'>
          <div className='col-md-10 d-flex justify-content-end'>
            {row?.original?.urlStatus !== "0" && row.original.urlStatus !== '' ?
              <span className='text-dark fw-bolder text-hover-primary  mb-1 fs-6'>
                {row.original.urlStatus}
              </span> : ''
            }
          </div>
        </div>
      )
    },
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Point Of Contact' className='min-w-150px' isSortable={false} />
    ),
    accessor: 'pmPl',
    Cell: ({ row }) => {

      return <div className='row'>
        <div className='col-md-12'>
          <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
            {row?.original?.pmPl?.length !== 0
              ? row?.original?.pmPl &&
              row?.original?.pmPl?.map((item: any, index: any) => (
                <span className={`badge badge-secondary me-2 mb-1`} key={`tag-${index}`}>
                  {item.name}{' '}({item.designation.split(' ').map((e: any) => e[0])})
                </span>
              ))
              : ''}
          </span>
        </div>
      </div>
    },
  },

]

export { usersColumns }
