import { FC } from 'react'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import PortfolioFilter from './PortfolioFilter'
import { DataTable } from './table/DataTable'
import { PageTitle } from '../../../_metronic/layout/core'

const UsersList: FC = () => {

  return (
    <>
      <PageTitle breadcrumbs={[]}>Portfolio</PageTitle>
      <KTCard className='my-7'>
        <KTCardBody className='p-0 p-md-7'>
          <DataTable />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default function PortFolioWrapper() {
  return (
    <div className='content flex-row-fluid'>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <PortfolioFilter />
            <UsersList />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </div>
  )
}
