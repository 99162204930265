import clsx from 'clsx'
import React, { FC, useState } from 'react'
// import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  shallowEqual, useSelector,
  //  useDispatch
} from 'react-redux'
import { HeaderUserMenu } from '../../../partials'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'



const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
// ,toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [copied, setCopied] = useState(false)
  // copy Email address
  const copyEmailAddress = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {user.vProfileImg && user.vProfileImg ? (
            <img src={user.vProfileImg ? user.vProfileImg : ''} alt='Profile' />
          ) : (
            <div className='symbol symbol-50px'>
              <div className='symbol-label fs-2 fw-bold text-dark'>
                {' '}
                {`${user.vFirstName?.charAt(0)}${user.vLastName?.charAt(0)}`}
              </div>
            </div>
          )}
        </div>
        <HeaderUserMenu callback={copyEmailAddress} copy={copied} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* </div> */}
    </div>
  )
}

export { Topbar }
