/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, createContext, useContext, useEffect, useState } from 'react'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  taskAddButton?: boolean
  setTaskAddButton: (_taskAddButton: boolean) => void
  rightSideContent?: any
  setRightSideContent: (_content: any) => void
  activeBreadcrumbTitle?: any
  setActiveBreadcrumbTitle: (_title: string) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => { },
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => { },
  setPageDescription: (_description: string) => { },
  setTaskAddButton: (_taskAddButton: boolean) => { },
  setRightSideContent: (_description: any) => { },
  setActiveBreadcrumbTitle: (_title: string) => { }
})

const PageDataProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [activeBreadcrumbTitle, setActiveBreadcrumbTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [taskAddButton, setTaskAddButton] = useState<boolean>(false)
  const [rightSideContent, setRightSideContent] = useState<any>()
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    taskAddButton,
    setTaskAddButton,
    rightSideContent,
    setRightSideContent,
    activeBreadcrumbTitle,
    setActiveBreadcrumbTitle
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  breadcrumbsActiveTitle?: string
}

const PageTitle: FC<Props> = ({ children, description, breadcrumbs, breadcrumbsActiveTitle }) => {

  const { setPageTitle, setPageDescription, setPageBreadcrumbs, setActiveBreadcrumbTitle } = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (breadcrumbsActiveTitle) {
      setActiveBreadcrumbTitle(breadcrumbsActiveTitle)
    }
    return () => {
      setActiveBreadcrumbTitle('')
    }
  }, [breadcrumbsActiveTitle])

  return <></>
}

const PageDescription: React.FC = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}



export { PageDescription, PageTitle, PageDataProvider, usePageData }
