import axios from 'axios'
import { parseRequestQuery } from './crud-helper';
// import { UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getDashboardData = (postData: any): Promise<any> => {

  const data = parseRequestQuery(postData)
  return axios
    .post(`${API_URL}/list`, data)
    .then((response) => {
      return response.data
    }
    )
}

const getFilterOptions = (): Promise<any> => {
  return axios
    .get(`${API_URL}/filter-options`)
    .then((response) => {
      return response.data
    }
    )
};


export {
  getFilterOptions,
  getDashboardData,
}
