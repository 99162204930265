import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { getUserData } from '../components'
import moment from 'moment'
// import { useRef } from 'react'

const DateRangePickerField = ({
  selectionRange,
  handleOnSelect,
  className,
  minDate,
  maxDate,
  staticRange
}: any) => {
  const user: any = getUserData()
  let DateFormat = 'dd-MMM-yyyy'
  if (
    user !== undefined &&
    user.vDateFormat !== undefined &&
    user.vDateFormat !== null &&
    user.vDateFormat !== ''
  ) {
    DateFormat = user.vDateFormat.replace(/DD/g, 'dd')
  }
  let min
  let max

  if (minDate) {
    min = new Date(minDate)
  } else {
    min = new Date(2003, 1, 1)
  }

  if (maxDate) {
    max = new Date(maxDate)
  } else {
    max = moment().add(10, 'years').toDate()
  }



  return (
    <DateRangePicker
      startDatePlaceholder='Start Date'
      endDatePlaceholder='End Date'
      minDate={min}
      maxDate={max}
      staticRanges={staticRange ? [] : undefined}
      inputRanges={[]}
      ranges={[selectionRange]}
      onChange={handleOnSelect}
      dateDisplayFormat={DateFormat}
      className={`date-range-picker ${className}`}
      ariaLabels={{ monthPicker: 'year-aria' }}
    />
  )
}

export default DateRangePickerField
