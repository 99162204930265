import { useEffect } from "react";

function Loader() {

  useEffect(() => {
    document.getElementById("root")?.classList.add('gray-body');
    document.getElementById("splash-screen")!.style.display = 'flex'
    return () => {
      document.getElementById("root")?.classList.remove('gray-body');
      document.getElementById("splash-screen")!.style.display = 'none';
    }
  });

  return (
    <></>
    // <div className='d-none d-md-block'>
    //   <div className='d-flex justify-content-center align-items-center'>
    //     <div id='splash-screen' className='splash-screen'>
    //       <img src='/media/logos/zealoverview-icon.gif' alt='Loading...' />
    //     </div>
    //   </div>
    // </div>
  )
}

export default Loader
