// import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { PortFolios } from '../../core/_models'

type Props = {
  row: Row<PortFolios>
}

const getHeader = (cell: any) => {
  return cell?.column?.Header()?.props?.title
}

const CustomRow: FC<Props> = ({ row }) => (
  <tr {...row.getRowProps()}
  //  className={`${row.original.urlStatus === '500' ? "opacity-50" : ""}`} 
  >
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          // className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          data-title={(getHeader(cell))}
        >
          <div>{cell.render('Cell')}</div>
        </td>
      )
    })}
  </tr>
)

export { CustomRow }
