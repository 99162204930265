import moment from 'moment'
import { useEffect, useRef, useState, useMemo } from 'react'
import { DateRangePickerField, SelectSearchField } from '../../components'
import { initialQueryRequest } from './core/crud-helper'
import { useQueryRequest } from './core/QueryRequestProvider'
import { useQueryResponse } from './core/QueryResponseProvider'
import { globalDropdown } from './core/_models'
import { getFilterOptions } from './core/_requests'


export default function PortfolioFilter() {
  const dateref = useRef<any>()
  const endaProtected = useMemo(() => [{
    id: 0,
    name: "No"
  }, {
    id: 1,
    name: "Yes"
  }], []);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [clientList, setClientList] = useState<globalDropdown[]>([])
  const [technology, setTechnology] = useState<globalDropdown[]>([])
  const [framework, setFramework] = useState<globalDropdown[]>([])
  const [service, setService] = useState<globalDropdown[]>([])
  const [industry, setIndustry] = useState<globalDropdown[]>([])
  const [urlStatus, setUrlstatus] = useState<globalDropdown[]>([])
  const [ndaProtected, setNDAProtected] = useState<globalDropdown[]>([])
  const [state, setState] = useState<any>({
    selectionRange: {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
    projectName: '',
    eNDAProtected: '',
    clientList: [],
    technology: [],
    framework: [],
    service: [],
    industry: [],
    urlStatus: [],
  })
  const [initaialLoading, setInitialLoading] = useState(true)
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()

  // Show only loader initial level
  useEffect(() => {
    if (initaialLoading && !isLoading) {
      setInitialLoading(false)
    }
  }, [isLoading, initaialLoading])

  useEffect(() => {
    let handler = (e: any) => {
      if (!dateref.current.contains(e.target)) {
        setShowDateRangePicker(false)
      }
    }
    document.addEventListener("mouseup", handler)
    return () => {
      document.removeEventListener("mouseup", handler)
    }
  }, [])
  useEffect(() => {

    getFilterOptions()
      .then((response) => {
        // console.log(response.data)
        if (response.status === 200) {
          setClientList(response.data.client)
          setTechnology(response?.data?.technology)
          setFramework(response?.data?.framework)
          setService(response?.data?.service)
          setIndustry(response?.data?.industry)
          setUrlstatus(response?.data?.urlStatus)
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    setNDAProtected(endaProtected)
  }, [endaProtected])
  // date-range picker
  const handleOnSelect = (ranges: any) => {
    let tempState = { ...state }
    tempState.selectionRange = ranges.selection
    setState(tempState)
    setShowDateRangePicker(false)
  }

  const handleOnChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    setState({ ...state, [name]: value })
  }

  const onSubmit = (event: any) => {
    event?.preventDefault()
    const client = state.clientList ? state.clientList?.map((item: any) => item.id) : []
    const urlStatus = state.urlStatus ? state.urlStatus?.map((item: any) => item.id) : []
    const technology = state.technology ? state.technology?.map((item: any) => item.id) : []
    const framework = state.framework ? state.framework?.map((item: any) => item.id) : []
    const service = state.service ? state.service?.map((item: any) => item.id) : []
    const industry = state.industry ? state.industry?.map((item: any) => item.id) : []

    updateState({
      ...initialQueryRequest.state,
      filter: {
        projectName: state && state.projectName ? state.projectName : null,
        dateFrom: state.selectionRange.startDate ? moment(state.selectionRange.startDate).format('YYYY-MM-DD') : null,
        dateTo: state.selectionRange.endDate ? moment(state.selectionRange.endDate).format('YYYY-MM-DD') : null,
        clientId: client && client.length > 0 ? client : null,
        technology: technology && technology.length > 0 ? technology : null,
        industry: industry && industry.length > 0 ? industry : null,
        service: service && service.length > 0 ? service : null,
        framework: framework && framework.length > 0 ? framework : null,
        url_status: urlStatus && urlStatus.length > 0 ? urlStatus : null,
        eNDAProtected: state.eNDAProtected ? state.eNDAProtected.id.toString() : null
      }
    })
  }

  const resetFilter = () => {
    setState({
      selectionRange: {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
      projectName: '',
      clientList: [],
      technology: [],
      industry: [],
      framework: [],
      service: [],
      urlStatus: '',
      eNDAProtected: ''
    })
    updateState({
      filter: {
        projectName: null,
        dateFrom: null,
        dateTo: null,
        clientList: null,
        technology: null,
        industry: null,
        framework: null,
        service: null,
        url_status: null,
        eNDAProtected: null
      },
      ...initialQueryRequest,
    })
  }

  return (

    <div className='card'>
      <div className='card-body p-4'>
        <div className='col-md-12'>
          <form className='form' noValidate onSubmit={(e) => onSubmit(e)}>
            <div className='fv-row row align-items-center mb-2'>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Clients</label>
                <SelectSearchField
                  value={state.clientList}
                  options={clientList}
                  isSearchable={true}
                  name='clientList'
                  isLoading={false}
                  loadingMessage='Featching clients'
                  noOptionsMessage='Clients are not available.'
                  placeholder='Select client'
                  handleOnChange={handleOnChange}
                  multiple={true}
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Project</label>
                <input
                  className='form-control'
                  type='text'
                  name='projectName'
                  placeholder='Project name'
                  onChange={handleOnChange}
                  value={state.projectName}
                />
              </div>
              <div
                className='col-12 col-md-6 col-lg-3 px-2 pb-3 date-range-picker-parent'
                ref={dateref}
              >
                <label className='fw-bold fs-6 mb-2'>Start date</label>
                <input
                  className='form-control'
                  type='text'
                  name='startDate'
                  autoComplete='off'
                  onChange={() => setShowDateRangePicker(!showDateRangePicker)}
                  value={
                    state.selectionRange.startDate && state.selectionRange.endDate
                      ? `${moment(state.selectionRange.startDate).format(
                        'DD-MMM-YYYY'
                      )}  -  ${moment(state.selectionRange.endDate).format('DD-MMM-YYYY')}`
                      : ''
                  }
                  onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                  placeholder='Start date'
                />
                {showDateRangePicker && (
                  <DateRangePickerField
                    selectionRange={state.selectionRange}
                    handleOnSelect={handleOnSelect}
                    className={'timelog-daterange'}
                  />
                )}
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>URL Status</label>
                <SelectSearchField
                  value={state.urlStatus}
                  options={urlStatus}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='urlStatus'
                  isLoading={false}
                  loadingMessage='Featching Url status'
                  noOptionsMessage='Url status are not available.'
                  placeholder='Select Url status'
                  multiple={true}
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Technology</label>
                <SelectSearchField
                  value={state.technology}
                  options={technology}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='technology'
                  isLoading={false}
                  loadingMessage='Featching technology'
                  noOptionsMessage='Technology are not available.'
                  placeholder='Select technology'
                  multiple={true}
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Framework</label>
                <SelectSearchField
                  value={state.framework}
                  options={framework}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='framework'
                  isLoading={false}
                  loadingMessage='Featching framework'
                  noOptionsMessage='Framework are not available.'
                  placeholder='Select framework'
                  multiple={true}
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>NDA Protected</label>
                <SelectSearchField
                  value={state.eNDAProtected}
                  options={ndaProtected}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='eNDAProtected'
                  isLoading={false}
                  loadingMessage='Featching NDA Protected'
                  noOptionsMessage='NDA Protected are not available.'
                  placeholder='Select NDA Protected'
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Service</label>
                <SelectSearchField
                  value={state.service}
                  options={service}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='service'
                  isLoading={false}
                  loadingMessage='Featching service'
                  noOptionsMessage='Service are not available.'
                  placeholder='Select service'
                  multiple={true}
                />
              </div>
              <div className='col-12 col-md-6 col-lg-3  px-2 pb-3 '>
                <label className='fw-bold fs-6 mb-2'>Industry</label>
                <SelectSearchField
                  value={state.industry}
                  options={industry}
                  isSearchable={true}
                  handleOnChange={handleOnChange}
                  name='industry'
                  isLoading={false}
                  loadingMessage='Featching industry'
                  noOptionsMessage='Industry are not available.'
                  placeholder='Select industry'
                  multiple={true}
                />
              </div>
              {/* Submission Button */}
              <div className={`col-md-12 text-center pt-5 px-2 d-flex justify-content-end`}>
                <button
                  disabled={isLoading && !initaialLoading}
                  type='button'
                  onClick={() => resetFilter()}
                  className='btn btn-secondary btn-sm me-3'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  <span className='indicator-label'>Reset</span>
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-sm me-3'
                  data-kt-users-modal-action='submit'
                  disabled={isLoading && !initaialLoading}
                  onClick={(e) => { onSubmit(e) }}
                >
                  <span className='indicator-label'>Search</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}