import store from '../../setup/redux/Store';
import moment from 'moment';

function getTimeFromMins(mins: any) {
  var h = mins / 60 | 0,
    m = mins % 60 | 0;

  h = Math.round(h)
  m = Math.round(m)
  // return moment().hours(h).minutes(m).format("hh:mm");
  return `${padTo2Digits(h)}:${padTo2Digits(m)}`;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function getAccessToken() {
  return store.getState().auth.accessToken;
}

function getUserData() {
  return store.getState().auth.user;
}

// get login type web ot mobile(token base)
function getViaLoginType() {
  return store.getState().auth.loginUsingToken;
}

function DateFormat(date: any) {
  let user = getUserData();
  let format = user?.vDateFormat ? user.vDateFormat : 'DD-MMM-YYYY'
  return moment(date).utc().format(format);
}

function onlyDateFormat(date: any, format?: string) {
  let user = getUserData();
  let userformat = (user?.vDateFormat ? user.vDateFormat : 'DD-MMM-YYYY')
  return moment(date, format).format(userformat);
}

function onlyDateTimeFormat(date: any, format?: string) {
  let user = getUserData();
  let userformat = (user?.vDateFormat ? user.vDateFormat + ' hh:mm a' : 'DD-MMM-YYYY hh:mm A')
  return moment(date).format(userformat);
}

function getDayFromDate(date: any, format: any, dayFormat?: any) {
  return moment(date, format).format(dayFormat ? dayFormat : "dddd");
}

function periodFromNow(date: any, format?: string) {
  return moment(moment(date, format).toDate()).startOf('seconds').fromNow()
}

function ConvertInDate(date: any, format?: string) {
  const newDate = date
  let user = getUserData();
  let userDateFormat = user?.vDateFormat ? user.vDateFormat : 'DD-MMM-YYYY'
  return moment(moment(newDate, (format ? format : userDateFormat)).format(userDateFormat), userDateFormat).toDate();
}

function timeToMinutes(time: any) {
  const timeArray = time.split(':');
  return (parseInt(timeArray[0]) * 60) + parseInt(timeArray[1]);
}

const finacialYears = (notAddFutureYear?) => {
  const AddYear = Number(process.env.REACT_APP_FINANCIAL_ADD_YEAR)
  const StartYear = notAddFutureYear ? 2000 : Number(process.env.REACT_APP_FINANCIAL_START_YEAR)
  const currentYear = new Date().getFullYear();
  const featureYear = currentYear + AddYear
  const finacialYears: { id: Number, name: string }[] = []
  let index = 0;
  for (var i = StartYear; i < featureYear; i++) {
    let next = i + 1;
    let year = i + '-' + next;
    finacialYears.push({ id: index, name: year });
    index = index + 1;
  }

  return finacialYears.reverse()
}

function isQuillEmpty(value: string) {
  const newVlaue = value;
  if (newVlaue?.replace(/<(.|\n)*?>/g, '').replaceAll('&nbsp;', '').trim().length === 0 && !newVlaue.includes("<img")) {
    return true;
  }
  return false;
}

function logDateTimeFormat(date: any, format?: string) {
  let today = moment(new Date(), 'YYYY-MM-DD HH:mm').format('DD-MMM-YYYY')
  let yesterday = moment(today, 'DD-MMM-YYYY').subtract(1, 'day').format('DD-MMM-YYYY')
  let commentedDate = moment(date, 'YYYY-MM-DD HH:mm').format('DD-MMM-YYYY')

  if (yesterday === commentedDate) {
    return 'Yesterday at ' + moment(date, 'YYYY-MM-DD HH:mm').format('hh:mm A')
  } else if (today === commentedDate) {
    return moment(date, 'YYYY-MM-DD HH:mm').format('hh:mm A')
  } else {
    let user = getUserData()
    let userformat = user?.vDateFormat ? user.vDateFormat + ' hh:mm A' : 'DD-MMM-YYYY hh:mm A'
    return moment(date).format(userformat)
  }
}

function getHoverDateTimeFormate(date: any, format?: string) {
  let user = getUserData()
  let userformat = user?.vDateFormat ? user.vDateFormat + ' hh:mm A' : 'DD-MMM-YYYY hh:mm A'

  return moment(date).format(userformat)
}


export {
  getTimeFromMins,
  getUserData,
  getViaLoginType,
  DateFormat,
  onlyDateFormat,
  getAccessToken,
  getDayFromDate,
  ConvertInDate,
  padTo2Digits,
  timeToMinutes,
  finacialYears,
  onlyDateTimeFormat,
  periodFromNow,
  isQuillEmpty,
  logDateTimeFormat,
  getHoverDateTimeFormate
}